import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Text, Title } from "@telegram-apps/telegram-ui";
import { Spinner } from "@telegram-apps/telegram-ui";
import { observer } from "mobx-react-lite";
import { userStore } from "../stores/UserStore";
import { autorun } from "mobx";
import { Button } from "@telegram-apps/telegram-ui";
import { URL } from "../api/api";

const QrWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  margin-bottom: 6rem;
  row-gap: 0.5rem;
`;

const QrPanel = observer(() => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const dispose = autorun(() => {
      console.log(userStore.user.plan);
      if (userStore.user?.tg_user_id) {
        setLoading(false);
      } else {
        setLoading(true);
      }
    });

    return () => dispose(); // очистка autorun при размонтировании компонента
  }, []);
  return (
    <>
      {loading ? (
        <Spinner size="m" />
      ) : (
        <>
          <QrWrapper>
            <Text weight="3">Покажите этот код сотруднику</Text>
            <img
              className="Qr_image"
              src={`${URL}/qr/${userStore.user?.qr_code_image}`}
              alt="QR code"
            ></img>
            <Title level="1" weight="3">
              {userStore.user?.tg_user_id || "Пусто"}
            </Title>
            <Button Component="a" href="tel:+79556725887" stretched={true}>
              Забронировать столик
            </Button>
          </QrWrapper>
        </>
      )}
    </>
  );
});

export default QrPanel;
