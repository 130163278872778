import React, { useEffect, useState } from "react";
import { Button, Modal, Select } from "@telegram-apps/telegram-ui";
import ContentWrapper from "../wrappers/ContentWrapper";
import { editUsers } from "../api/api";

const EditUserModal = ({ state, onChangeUser }) => {
  const [role, setRole] = useState(state.role);

  async function changeEditeRole(role) {
    try {
      const newUser = await editUsers(state.tg_user_id, role);
      if (newUser) {
        setRole(role);
        if (onChangeUser) {
          onChangeUser(newUser);
        }
      }
    } catch (error) {
      console.error("Ошибка при обновлении пользователя:", error);
      throw error; // Пробрасываем ошибку, если нужно обработать ее выше
    }
  }
  return (
    <>
      <Modal trigger={<Button size="m">Редактировать</Button>}>
        <ContentWrapper>
          <Select
            onChange={(e) => changeEditeRole(e.target.value)}
            header="Роль"
            value={role}
            placeholder="Установить роль"
          >
            <option>User</option>
            <option>Admin</option>
          </Select>
        </ContentWrapper>
      </Modal>
    </>
  );
};

export default EditUserModal;
