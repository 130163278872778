import axios from 'axios'
import { toast } from 'react-hot-toast';
// import { getTokenFromCookie } from '../cookie/cookie'
import { getTokenFromCookie } from '../cookie/cookie'
export const URL = "https://six-app.ru/six_app_api"
export const URL_SOCET = 'https://six-app.ru'



// Создаем кастомный инстанс axios
const apiClient = axios.create({
    baseURL: URL, // Базовый URL для всех запросов
});

// Добавляем request interceptor
apiClient.interceptors.request.use(
    (config) => {
        // Получаем токен (например, из localStorage или cookies)
        const token = getTokenFromCookie();
        if (token) {
            config.headers.Authorization = `Bearer ${token}`; // Добавляем токен в заголовки
        }
        return config; // Возвращаем измененный конфиг
    },
    (error) => {
        // Обрабатываем ошибки перед отправкой запроса
        return Promise.reject(error);
    }
);
// Получить пользоваетеля
export async function Login(user_tg_id) {
    try {
        const response = await apiClient.post(`${URL}/user/login?tg_user_id=${user_tg_id}`);

        return response.data

    } catch (error) {
        console.error(error);
        toast(`Неаудалось получить данные: ${error.message}`, { type: "error" });
        return null
    }
}
// Списать промо
// 'six_app/user/remove/promo?tg_user_id=6935066908'
export async function removePromo(user_tg_id) {
    try {
        const response = await apiClient.post(`${URL}/user/remove/promo?tg_user_id=${user_tg_id}`);
        toast(`Успешно использовали промо!`, { type: "success" })
        return response.data

    } catch (error) {
        console.error(error);
        toast(`Неаудалось списать пкупку: ${error.message}`, { type: "error" });
        return null
    }
}
// Добавить промо
// 'http://localhost:3001/six_app/user/add/buy?tg_user_id=6935066908'
export async function addBuy(user_tg_id) {
    try {
        const response = await apiClient.post(`${URL}/user/add/buy?tg_user_id=${user_tg_id}`);
        toast(`Успешно добавили покупку!`, { type: "success" })
        return response.data

    } catch (error) {
        console.error(error);
        toast(`Неаудалось добавить покупку: ${error.message}`, { type: "error" });
        return null
    }
}

export async function getAllUsers() {
    try {
        const response = await apiClient.post(`${URL}/user/upload`);
        // toast(`Успешно добавили покупку!`, { type: "success" })
        return response.data

    } catch (error) {
        console.error(error);
        toast(`Неаудалось получить юзеров: ${error.message}`, { type: "error" });
        return null
    }
}


export async function editUsers(tg_user_id, role) {
    try {
        const response = await apiClient.put(`${URL}/user/edite`, {
            tg_user_id: tg_user_id,
            role: role
        });
        toast(`Успешно изменили данные`, { type: "success" })
        return response.data

    } catch (error) {
        console.error(error);
        toast(`Неаудалось изменить данные: ${error.message}`, { type: "error" });
        return null
    }
}

