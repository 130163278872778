import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { getAllUsers } from "../api/api";
import styled from "styled-components";
import {
  Spinner,
  Text,
  Blockquote,
  Button,
  Modal,
} from "@telegram-apps/telegram-ui";
import EditUserModal from "../components/EditUserModal";

const PanelWrapper = styled.div`
  display: flex;
  padding: 1rem 1rem 6rem 1rem;
  flex-direction: column;
  row-gap: 1rem;
`;
const StyledCardTask = styled.div`
  position: relative;
  display: flex;
  overflow: hidden;
  border-radius: 20px;
  padding: 1rem;
  box-shadow: 0 32px 64px 0 rgba(0, 0, 0, 0.04), 0 0 2px 1px rgba(0, 0, 0, 0.02);
  background: var(--tgui--card_bg_color);
  flex-direction: column;
  row-gap: 0.5rem;
`;
const UsresSettings = observer(() => {
  const [loading, setLoading] = useState(false);
  const [userList, setUserList] = useState([]);

  const handelgetAllUsers = async () => {
    setLoading(true);
    let res = await getAllUsers();
    if (res) {
      setUserList(res);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    handelgetAllUsers();
  }, []);
  function formatDate(dateInput) {
    // Преобразуем входное значение в объект Date, если это не объект Date
    const date = dateInput instanceof Date ? dateInput : new Date(dateInput);

    // Проверяем, удалось ли создать объект Date
    if (isNaN(date.getTime())) {
      throw new Error("Invalid date input");
    }

    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();

    return `${day}.${month}.${year}`;
  }

  return (
    <>
      {" "}
      <PanelWrapper>
        {userList.map((user) => {
          return (
            <>
              <StyledCardTask key={user.id}>
                {/* {JSON.stringify(user)} */}
                <Text weight="2">@{user.tg_user_name} </Text>
                <Text weight="2">{user.tg_user_id} </Text>
                <Text weight="3">id: {user?.id ? user?.id : "пусто"}</Text>
                <Text weight="2">
                  Роль: {user?.role ? user?.role : "пусто"}
                </Text>
                <Text weight="2">
                  Телефон: {user?.phone ? user?.phone : "пусто"}
                </Text>
                <Text weight="2">Покупок: {user?.count}</Text>
                <Text weight="2">Промо: {user?.promotio_count}</Text>
                <Text weight="2">
                  Акуция действует до: {formatDate(user.promotio_date)}
                </Text>
                <br />
                <EditUserModal
                  state={user}
                  onChangeUser={(e) => console.log(e)}
                />
              </StyledCardTask>
            </>
          );
        })}
      </PanelWrapper>
    </>
  );
});

export default UsresSettings;
