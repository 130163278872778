import { Html5Qrcode } from "html5-qrcode";
import { useEffect, useState } from "react";
import { Modal, Button, Section, Text } from "@telegram-apps/telegram-ui";
import QrModalContetntWrapper from "../wrappers/QrModalContetntWrapper";
import { MdAddBox } from "react-icons/md";
import { addBuy, removePromo } from "../api/api";

const qrcodeRegionId = "html5qr-code-full-region";

const Html5QrcodePlugin = () => {
  const config = { fps: 10, qrbox: { width: 300, height: 300 } };
  const [isEnabled] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [error, setError] = useState(null);
  const [result, setResult] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const [loadingBtn, setLoadingBtn] = useState(false);

  async function handelRemovePromo() {
    setDisabled(true);
    setLoadingBtn(true);

    let res = await removePromo(result);
    if (res) {
      // alert(JSON.stringify(res));
      setDisabled(false);
      setLoadingBtn(false);
    } else {
      setDisabled(false);
      setLoadingBtn(false);
    }
  }

  async function handelAddBuy() {
    setDisabled(true);
    setLoadingBtn(true);

    let res = await addBuy(result);
    if (res) {
      // alert(JSON.stringify(res));
      setDisabled(false);
      setLoadingBtn(false);
    } else {
      setDisabled(false);
      setLoadingBtn(false);
    }
  }

  useEffect(() => {
    const html5QrcodeScanner = new Html5Qrcode(qrcodeRegionId);

    if (isEnabled) {
      html5QrcodeScanner
        .start({ facingMode: "environment" }, config, (decodedText) => {
          console.log("Success:", decodedText);
          setResult(decodedText);
          setOpenModal(true);
          setError(null);
        })
        .catch((startError) => {
          console.log("Start Error:", startError);
          setError(startError);
        });
    }
  }, [isEnabled]);

  return (
    <div>
      {/* {JSON.stringify(openModal)}
      <Button
        onClick={() => {
          setResult("6935066908");
          setOpenModal(!openModal);
        }}
      >
        test btn
      </Button> */}
      <Modal
        onOpenChange={(open) => {
          setOpenModal(open);
        }}
        open={openModal}
      >
        <Section footer="Версия: s1.0.1" header="Начисление баллов">
          <QrModalContetntWrapper>
            <Text weight="2">Найден пользователь с id: {result}</Text>
            <Button
              loading={loadingBtn}
              disabled={disabled}
              onClick={() => handelAddBuy()}
              after={<MdAddBox />}
            >
              Добавить кальян
            </Button>
            <Button
              loading={loadingBtn}
              disabled={disabled}
              onClick={() => handelRemovePromo()}
            >
              Списать беcплатный кальян
            </Button>
          </QrModalContetntWrapper>
        </Section>
      </Modal>
      {/* {result && <p>Scan Success: {result}</p>} */}
      {error && <p style={{ color: "red" }}>Error: {error}</p>}
      <div id={qrcodeRegionId} />
    </div>
  );
};

export default Html5QrcodePlugin;
