import '@telegram-apps/telegram-ui/dist/styles.css';
import './App.css';
import React, { useEffect, useState } from "react";
import { AppRoot } from '@telegram-apps/telegram-ui';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Toaster } from 'react-hot-toast';
// import Admin from './pages/Admin'
import User from './pages/User'
import User_Panel from './pages/User_Panel'
import { Login } from './api/api'
import { userStore } from './stores/UserStore'
import Menu from './components/menu'
import UsresSettings from './pages/UsresSettings'
import QrReaderScan from './components/Html5QrcodePlugin'
import { QR_SCANNER, USER_PANEL, USRES_SETTINGS } from './constants/routes'
import { io } from 'socket.io-client';
import { URL_SOCET } from './api/api'
import { toast } from 'react-hot-toast';
import { ADMIN, USER } from './constants/roles'
import { saveTokenToCookie } from './cookie/cookie'

function App() {
  const [role, setRole] = useState(null);
  // 6182050210 6935066908
  const tgMetodGetId = () => { return '6182050210' }


  async function initUser(tg_user_id) {
    const userData = await Login(tg_user_id);
    if (userData) {
      console.log("Записали юзера в store:", userData.user);
      console.log(userData)
      // saveTokenToCookie(userData.token)
      userStore.setUser(userData.user); // записываем пользователя в store
      setRole(userData.user.role)
      SocketConnect(String(userData.user.tg_user_id))
      saveTokenToCookie(userData.token)
      // setRole(userData.user.role) 
    }
  }

  // <script src="https://telegram.org/js/telegram-web-app.js" ></script>
  useEffect(() => {
    if (window.Telegram?.WebApp) {
      const tg = window.Telegram.WebApp;
      //   // Подготавливаем Web App
      tg.ready();
      //   // Расширяем на весь экран
      tg.expand();
      const tg_user_id = tg?.initDataUnsafe?.user?.id || null;
      console.log(String(tg_user_id))
      initUser(String(tg_user_id))

    } else {
      initUser(tgMetodGetId())
      toast('Ошибка входа! Открой в телеграмм', { type: "error" })
    }
  })

  function SocketConnect(tg_user_id) {
    const newSocket = io(URL_SOCET, {
      query: {
        tg_user_id: tg_user_id,
      },
    });
    // Обработчики событий подключения
    newSocket.on('connect', () => {
      console.log('Connected to WebSocket server:', newSocket);
    });

    newSocket.on('updatePromotio', (message) => {
      toast(`${message}`);
      console.log('Received message:', message); // Получаем и выводим сообщение от сервера
    });

    newSocket.on('updateUser', (message) => {
      userStore.setUser(message);
      console.log('Received message:', message); // Получаем и выводим сообщение от сервера
    });


  }


  return (
    <>
      <AppRoot
        appearance="dark"
      >
        <Router>
          {/* {JSON.stringify(role)} */}
          <Menu />
          <Toaster />
          <Routes>
            {role === USER && (
              <>
                <Route path="/" element={<User />} />
                <Route path={USER_PANEL} element={<User_Panel />} />
              </>
            )}
            {role === ADMIN && (
              <>
                <Route path="/" element={<UsresSettings />} />

                <Route path={USRES_SETTINGS} element={<UsresSettings />} />
                <Route path={QR_SCANNER} element={<QrReaderScan />} />
              </>
            )}


          </Routes>
        </Router>
      </AppRoot>
    </>
  );
}

export default App;
