import React, { useEffect, useState } from "react";
import styled from "styled-components";
import image from "../images/1.png";
import { Spinner } from "@telegram-apps/telegram-ui";
import { observer } from "mobx-react-lite";
import { userStore } from "../stores/UserStore";
import { autorun } from "mobx";

const CardWrapper = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 1rem;
  padding: 1rem;
  margin: 1rem;
  background-color: red;
  border-radius: 1rem;
  height: 8rem;
  position: relative;

  overflow: hidden;
  justify-content: space-between;
  background: linear-gradient(70deg, #cda652, #694c0e);
`;

const MarcetingCard = observer(() => {
  const [loading, setLoading] = useState(true);
  function formatDate(dateInput) {
    // Преобразуем входное значение в объект Date, если это не объект Date
    const date = dateInput instanceof Date ? dateInput : new Date(dateInput);

    // Проверяем, удалось ли создать объект Date
    if (isNaN(date.getTime())) {
      throw new Error("Invalid date input");
    }

    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();

    return `${day}.${month}.${year}`;
  }

  useEffect(() => {
    const dispose = autorun(() => {
      console.log(userStore.user.plan);
      if (userStore.user?.tg_user_id) {
        setLoading(false);
      } else {
        setLoading(true);
      }
    });

    return () => dispose(); // очистка autorun при размонтировании компонента
  }, []);

  return (
    <>
      {/* {JSON.stringify(userStore.user)} */}
      {loading ? (
        <Spinner size="m" />
      ) : (
        <>
          <CardWrapper>
            <img
              className="MarcetingCard_image"
              src={image}
              alt="Example Image"
            ></img>
            <div className="MarcetingCard_info_block_1">
              <span style={{ fontSize: "3rem", color: "#fff" }}>
                {userStore.user?.count}/{userStore.user.plan || "Пусто"}
              </span>

              <span style={{ fontSize: "1rem", color: "#fff" }}>
                {userStore.user?.promotio_date
                  ? formatDate(userStore.user.promotio_date)
                  : "Пусто"}
              </span>
            </div>
            <div className="MarcetingCard_info_block_2">
              <span style={{ fontSize: "4rem", color: "#fff" }}>
                {userStore.user?.promotio_count}
              </span>
              <span style={{ fontSize: "1rem", color: "#fff" }}>
                бесплатных
              </span>
            </div>
          </CardWrapper>
        </>
      )}
    </>
  );
});

export default MarcetingCard;
