import React, { useEffect, useState } from "react";
import { Spinner, Text, Blockquote, Button } from "@telegram-apps/telegram-ui";
import { userStore } from "../stores/UserStore";
import { autorun } from "mobx";
import { observer } from "mobx-react-lite";
import styled from "styled-components";

const PanelWrapper = styled.div`
  display: flex;
  padding: 1rem;
  flex-direction: column;
  row-gap: 1rem;
  margin-bottom: 6rem;
`;

const User_Panel = observer(() => {
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState(null);
  const [selectAccordion, setSelectAccordion] = useState(null);

  useEffect(() => {
    const dispose = autorun(() => {
      if (userStore.user?.tg_user_id) {
        setUserData(userStore.user);
        setLoading(false);
      } else {
        setLoading(true);
      }
    });

    return () => dispose(); // очистка autorun при размонтировании компонента
  }, []);

  return (
    <>
      <PanelWrapper>
        <Text weight="1">Условия акции: "6-й кальян в подарок"</Text>

        <Blockquote type="text">
          Купите 5 кальянов, и получите 6-й бесплатно.
          <br />
          Учёт начинается с первого добавления. <br />
          Акция действует в течение 2 месяцев с момента добавления первого
          кальяна.
          <br /> Срок продлевается на дополнительные 2 месяца с каждого
          добавленного кальяна. <br />
          Акция доступна только зарегистрированным участникам и действует в
          рамках одного периода. <br />
          После выполнения условий вы получите уведомление о подарочном кальяне,
          который можно использовать сразу. <br />
          Акция не комбинируется с другими предложениями.
        </Blockquote>

        {userData != null && (
          <>
            <Text weight="2">Телефон: {userData.phone}</Text>

            <Text weight="2">Имя пользователя: @{userData.tg_user_name}</Text>

            <Text weight="2">Накоплено калянов: {userData.count}</Text>

            <Text weight="2">
              Бесплатных калянов: {userData.promotio_count}
            </Text>
          </>
        )}

        <Button Component="a" href="tel:+79556725887">
          Забронировать столик
        </Button>
        <Button
          Component="a"
          href="https://hziid.tb.ru"
          mode="bezeled"
          target="_blank"
        >
          Наше меню
        </Button>
      </PanelWrapper>
      {/* {JSON.stringify(userData)} */}
    </>
  );
});

export default User_Panel;
