import MarcetingCard from "../components/MarcetingCard";
import QrPanel from "../components/QrPanel";
import logo2 from "../images/logo2.png";

const User = () => {
  return (
    <>
      <img className="logo2" src={logo2} />
      <MarcetingCard />
      <QrPanel />
    </>
  );
};

export default User;
