import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useLocation, useNavigate } from "react-router-dom";
import { Tabbar } from "@telegram-apps/telegram-ui";
import {
  QR_SCANNER,
  ADMIN_PAGE,
  USER_PANEL,
  USRES_SETTINGS,
  HOME_PATH,
} from "../constants/routes";
import { MdOutlineQrCode2, MdHome, MdOutlinePerson } from "react-icons/md";
import { ADMIN, USER } from "../constants/roles";
import { userStore } from "../stores/UserStore";

const Menu = observer(() => {
  const [path, setPath] = useState(HOME_PATH);
  const location = useLocation();
  const navigate = useNavigate();
  const goToContact = (path) => {
    navigate(path);
  };
  useEffect(() => {
    console.log(
      "URL изменился:",
      location.pathname + location.search + location.hash
    );
    setPath(location.pathname);
  }, [location]);
  return (
    <>
      <div style={{ position: "absolute", zIndex: "2" }}>
        <Tabbar>
          {userStore?.user?.role === USER && (
            <>
              <Tabbar.Item
                text="Главная"
                onClick={() => goToContact(HOME_PATH)}
                selected={path === HOME_PATH}
              >
                <MdHome size={16} />
              </Tabbar.Item>
              <Tabbar.Item
                text="Профиль"
                onClick={() => goToContact(USER_PANEL)}
                selected={path === USER_PANEL}
              >
                <MdOutlinePerson size={16} />
              </Tabbar.Item>
            </>
          )}
          {userStore?.user?.role === ADMIN && (
            <>
              <Tabbar.Item
                text="Пользователи"
                onClick={() => goToContact(USRES_SETTINGS)}
                selected={path === USRES_SETTINGS}
              >
                <MdOutlinePerson size={16} />
              </Tabbar.Item>
              <Tabbar.Item
                text="QR сканнер"
                onClick={() => goToContact(QR_SCANNER)}
                selected={path === QR_SCANNER}
              >
                <MdOutlineQrCode2 size={16} />
              </Tabbar.Item>
            </>
          )}
        </Tabbar>
      </div>
    </>
  );
});

export default Menu;
