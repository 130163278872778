import styled from "styled-components";

const StyledContentWrapper = styled.div`
  row-gap: 1rem;
  padding: 1rem 1rem 5rem 1rem;
  display: flex;
  flex-direction: column;
  background: var(--tgui--bg_color);
  min-height: calc(100vh - 6rem);
  // max-width: 1200px;
`;

// Компонента-обертка
const ContentWrapper = ({ children }) => {
  return <StyledContentWrapper>{children}</StyledContentWrapper>;
};

export default ContentWrapper;
